import React from 'react';

const RichTextContent = ({ blocks }) => {
  const renderBlock = (block) => {
    switch (block.style) {
      case 'h1':
        return <h1 key={block._key} style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>{renderInlineContent(block.children)}</h1>;
      case 'h2':
        return <h2 key={block._key} style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '12px' }}>{renderInlineContent(block.children)}</h2>;
      case 'h3':
        return <h3 key={block._key} style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>{renderInlineContent(block.children)}</h3>;
      case 'blockquote':
        return <blockquote key={block._key} style={{ borderLeft: '4px solid #ccc', paddingLeft: '16px', fontStyle: 'italic', margin: '16px 0' }}>{renderInlineContent(block.children)}</blockquote>;
      case 'normal':
      default:
        return <p key={block._key} style={{ marginBottom: '16px' }}>{renderInlineContent(block.children)}</p>;
    }
  };

  const renderInlineContent = (children) => {
    return children.map((child) => {
      if (child._type === 'span') {
        let style = {};
        if (child.marks && child.marks.includes('strong')) {
          style.fontWeight = 'bold';
        }
        if (child.marks && child.marks.includes('em')) {
          style.fontStyle = 'italic';
        }
        return <span key={child._key} style={style}>{child.text}</span>;
      }
      return null;
    });
  };

  return <>{blocks.map(renderBlock)}</>;
};

export default function EventDetails({ event }) {
  if (!event) return null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const styles = {
    image: {
      width: '60%',
      height: 'auto',
      marginTop: '2rem',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    occurrenceContainer: {
      display: 'flex'
    },
    occurrenceDate: {
      fontWeight: 'bold',
      marginBottom: '4px',
      marginRight: '8px',
    },
    occurrenceDetails: {
      fontWeight: 'bold',
      marginBottom: '4px',
      fontSize: '18px',
    },
    description: {
      marginTop: '2rem',
      color: '#333',
      fontSize: '16px',
    },
    button: {
      marginTop: '24px',
      backgroundColor: 'silver',
      color: 'black',
      fontWeight: 'bold',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '20px',
      cursor: 'pointer',
      fontSize: '16px',
    },
  };

  return (
    <div>
      <div>
        <h1 style={styles.title}>{event.title}</h1>

        <div style={styles.description}>
          <RichTextContent blocks={event.description} />
        </div>

        <div style={{ marginTop: '2rem' }}>
          <h2 style={styles.occurrenceDate}>Dates:</h2>
          
          {event.eventOccurrences.map((occurrence, index) => (
            <div key={index} style={styles.occurrenceContainer}>
              {occurrence.startDate !== occurrence.endDate ? (
                <p style={styles.occurrenceDetails}>
                  {formatDate(occurrence.startDate)} - {formatDate(occurrence.endDate)}
                </p>
              ) : occurrence.startDate && (
                <p style={styles.occurrenceDetails}>
                  {formatDate(occurrence.startDate)}
                </p>
              )}
              {occurrence.startDate && occurrence.locationName && (
                <span style={{ marginRight: '8px', marginLeft: '8px', fontWeight: 'bold' }}>
                  -
                </span>
              )}
              <p style={styles.occurrenceDetails}>
                {occurrence.locationName}
              </p>
            </div>
          ))}
        </div>

        {event.showButton && (
          <button 
            style={styles.button}
            onClick={() => window.open(event.buttonLink, '_blank')}
          >
            {event.buttonText}
          </button>
        )}
      </div>
      <img 
        src={event.featuredImage.asset.url} 
        alt={event.title} 
        style={styles.image}
      />
    </div>
  );
}